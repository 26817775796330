// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
import { getStorageData } from "../../../framework/src/Utilities";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions");
import { Moment } from "moment/moment";
import { IInterest } from "../../../components/src/CommonTypes";
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

export interface S {
    // Customizable Area Start
    token: string | null;
    userId: string;
    full_name: string;
    image_url: string;
    user_name: string;
    country_code: string;
    phone_number: number;
    date_of_birth: string | null;
    email: string;
    selectedInterests: IInterest[];
    currentSelectedInterests: IInterest[];
    interests: IInterest[];
    isImageUpdated: boolean;
    isInterestModalOpen: boolean;
    isNewInterestAddApiRunning: boolean;
    isCountryCodeOpen: boolean;
    countryCodes: {
        name: string;
        flag: string;
        phone_code: string;
    }[];
    countryCodeMenuAnchorEle: HTMLDivElement | null;
    countryMenuSearchString: string;
    // Customizable Area End
}

export interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class EditProfileDetailsController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getProfileDetailsApiCallId: string = "";
    fetchInterestsApiCallId: string = "";
    addNewInterestApiCallId: string = "";
    getCountryCodeApiCallId: string = "";
    updateProfileApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            token: "",
            userId: "",
            full_name: "",
            image_url: "",
            user_name: "",
            country_code: "+91",
            phone_number: 0,
            date_of_birth: "",
            email: "",
            interests: [],
            selectedInterests: [],
            currentSelectedInterests: [],
            isImageUpdated: false,
            isInterestModalOpen: false,
            isNewInterestAddApiRunning: false,
            isCountryCodeOpen: false,
            countryCodes: [],
            countryCodeMenuAnchorEle: null,
            countryMenuSearchString: ""
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        await super.componentDidMount();
        this.getToken();
        // Customizable Area Start        
        // Customizable Area End
    }

    receive = async (from: string, message: Message) => {
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let authToken = message.getData(getName(MessageEnum.SessionResponseToken));
            if (!authToken) {
                authToken = await getStorageData("authToken");
            }
            this.setToken(authToken);
        }
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            switch (webApiRequestCallId) {
                case this.getProfileDetailsApiCallId:
                    this.handleGetProfileDetailsApiCall(message)
                    break;
                case this.fetchInterestsApiCallId:
                    this.handleFetchInterestsAPICall(message);
                    break;
                case this.addNewInterestApiCallId:
                    this.handleAddNewInterestApiCall(message);
                    break;
                case this.updateProfileApiCallId:
                    this.handleUpdateProfileApiCall(message)
                    break;
            }
        }
    };

    getToken = () => {
        const message: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(message);
    };


    // Customizable Area Start

    setToken = async (token: string | null) => {
        this.setState({ token }, () => {
            this.fetchInterests();
        })
    }

    goBack = () => {
        this.props.navigation.goBack()
    }

    onSelectImage = (imageUrl: string, imageFile?: File) => {
        this.setState({
            image_url: imageUrl,
            isImageUpdated: true
        })
    }

    getProfileDetails = async () => {
        helper.showLoader();
        this.getProfileDetailsApiCallId = await helper.apiCall({
            method: "GET",
            contentType: configJSON.validationApiContentType,
            token: this.state.token,
            endPoint: `${configJSON.profileEndpoint}`
        })
    }

    handleGetProfileDetailsApiCall = (message: Message) => {
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseJson && responseJson.data) {
            const {
                interests
            } = this.state;
            this.setState({
                userId: responseJson.data.id,
                full_name: responseJson.data.attributes.full_name,
                image_url: responseJson.data.attributes.image_url,
                user_name: responseJson.data.attributes.user_name,
                country_code: `+${responseJson.data.attributes.country_code}`,
                phone_number: responseJson.data.attributes.phone_number,
                date_of_birth: responseJson.data.attributes.date_of_birth,
                email: responseJson.data.attributes.email,
                selectedInterests: responseJson.data.attributes.interests ? responseJson.data.attributes.interests.map((interest: { id: string, name: string }) => {
                    if (interests.find(item => item.id === interest.id)) {
                        return {
                            id: interest.id,
                            attributes: {
                                name: interest.name,
                                manual: false
                            }
                        }
                    } else {
                        return {
                            id: interest.id,
                            attributes: {
                                name: interest.name,
                                manual: true
                            }
                        }
                    }
                }) : []
            })
        }
        helper.hideLoader();
    }

    onInputChange = (name: string, value: string) => {
        this.setState(prevState => {
            return {
                ...prevState,
                [name]: value
            }
        })
    }

    openDatePicker = () => {
        const datePickerDialog = document.getElementById("mui-date-picker");
        if (datePickerDialog) {
            datePickerDialog.click();
        }
    }

    onDateChangeHandler = (value: Date | Moment | null) => {
        const formatDate = helper.formatDate(value, "YYYY-MM-DD");
        this.setState({
            date_of_birth: formatDate
        })
    }

    toggleInterestModal = () => {
        const {
            isInterestModalOpen,
            selectedInterests
        } = this.state;
        this.setState({
            isInterestModalOpen: !isInterestModalOpen,
            currentSelectedInterests: selectedInterests
        })
    }

    fetchInterests = async () => {
        this.fetchInterestsApiCallId = await helper.apiCall({
            method: "GET",
            contentType: configJSON.validationApiContentType,
            token: this.state.token,
            endPoint: configJSON.interestApiEndPoint
        })
    }

    handleFetchInterestsAPICall = (message: Message) => {
        const responseData = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (responseData && responseData.data) {
            this.setState({
                interests: responseData.data
            })
        } else {
            this.setState({
                interests: []
            })
        }
        this.getProfileDetails();
    }

    setCurrentSelectedInterest = (interests: IInterest[]) => {
        this.setState({
            currentSelectedInterests: interests
        })
    }

    onAddInterests = (interests: IInterest[]) => {
        this.setState({
            selectedInterests: interests,
            isInterestModalOpen: false,
            currentSelectedInterests: []
        })
    }

    onSubmitNewInterest = async (event: string) => {
        this.setState({
            isNewInterestAddApiRunning: true
        })
        this.addNewInterestApiCallId = await helper.apiCall({
            contentType: configJSON.validationApiContentType,
            method: "POST",
            endPoint: configJSON.addNewInterestApiEndPoint,
            body: {
                name: event
            }
        })
    }

    handleAddNewInterestApiCall = (message: Message) => {
        const response = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (response && response.data) {
            const modifiedResponseData = response.data;
            modifiedResponseData.attributes.manual = true;
            this.setState((prevState) => {
                return {
                    currentSelectedInterests: [...prevState.currentSelectedInterests, modifiedResponseData]
                }
            })
        }
        this.setState({
            isNewInterestAddApiRunning: false
        })
    }

    removeInterestFromSelectedInterest = (interestId: string) => {
        this.setState(prevState => {
            return {
                selectedInterests: prevState.selectedInterests.filter(interest => interest.id !== interestId)
            }
        })
    }

    disableSubmitButton = () => {
        const {
            full_name,
            user_name,
            phone_number,
            selectedInterests
        } = this.state;
        return (!full_name || !user_name || !phone_number || selectedInterests.length === 0);
    }

    onSubmit = async ()=>{
        helper.showLoader();
        const {
           userId,
           token
        } = this.state;
        this.updateProfileApiCallId = await helper.apiCall({
            method: "PUT",
            endPoint: `bx_block_user/user_profiles/${userId}`,
            contentType: configJSON.validationApiContentType,
            body: {
                account: this.getDataFormPost()
            },
            token
        });
    }

    getDataFormPost = ()=>{
        const {
          full_name,
          user_name,
          date_of_birth,
          email,
          selectedInterests,
          image_url,
          isImageUpdated
        } = this.state;

        let jsonData: any = {
            full_name,
            user_name,
            email,
            date_of_birth,
            interests: selectedInterests.map(interest=> parseInt(interest.id))
        }
        if(isImageUpdated){
            jsonData['profile_image'] = image_url;
        }
        return jsonData;
    }

    handleUpdateProfileApiCall = (message: Message)=>{
        const response = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(response && response.data){
            helper.showSuccessToast("Profile Updated")
            helper.setStorageData("userData", JSON.stringify(response.data.attributes));
            this.props.navigation.history.push("/profile?tab=3");
        }else if(response){
            const message = response.errors && response.errors[0] && response.errors[0].message ? response.errors[0].message : "Something went wrong!";
            helper.showErrorToast(message);
        }
        helper.hideLoader();
    }

    // Customizable Area End
}