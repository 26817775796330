import React from "react";
import { styled } from '@material-ui/styles';
interface ReminderFrequencyProps {
   selectedValue: string;
   onChange: (event: string)=>void;
}


const frequencyList = [
    { label: ["05", "Min"], value: "5" },
    { label: ["15", "Min"], value: "15" },
    { label: ["30", "Min"], value: "30" },
    { label: ["01", "Hr"], value: "60" },
    { label: ["02", "Hr"], value: "120" },
];


const ReminderFrequency: React.FC<ReminderFrequencyProps> = (props: ReminderFrequencyProps) => {
    const { selectedValue, onChange } = props;

    return (
        <StyledReminderFrequency className="add-reminder_reminder-frequency">
            <label>Select Reminder Frequency*</label>
            <div className="frequency-list">
                {
                    frequencyList.map(frequency => {
                        return (
                            <div key={frequency.value} className={`frequency ${selectedValue === frequency.value ? "selected" : ""}`} onClick={()=> onChange(frequency.value)}>
                                <p>{frequency.label[0]}</p>
                                <p>{frequency.label[1]}</p>
                            </div>
                        )
                    })
                }
            </div>
        </StyledReminderFrequency>
    )
}

const StyledReminderFrequency = styled("div")({
    "&.add-reminder_reminder-frequency": {
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
        "& > label": {
            fontSize: "0.875rem",
            fontWeight: 400,
            color: "rgba(242, 242, 242, 1)"
        },
        "& > .frequency-list": {
            display: "flex",
            gap: "1rem",
            flexWrap: "wrap",
            "& > .frequency": {
                width: "3.3125rem",
                height: "3.5rem",
                border: "1px solid rgba(25, 181, 185, 0.27)",
                borderRadius: "0.25rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                "& > p": {
                    color: "rgba(255, 255, 255, 1)",
                    fontSize: "1rem",
                    fontWeight: 400,
                    "&:nth-child(2)": {
                        fontSize: "0.75rem"
                    }
                },
                "&.selected": {
                    borderColor: "rgba(247, 83, 103, 1)",
                    background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
                    "& > p": {
                        color: "rgba(255, 105, 118, 1)"
                    }
                }
            }
        }
    }
})


export default ReminderFrequency;