// Customizable Area Start
import React from "react";
import EditProfileDetailsController, {
    Props
} from "./EditProfileDetailsController";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { styled } from "@material-ui/styles";
import { ImagePicker, GoBack, InputText, Button, SelectInterest, Chip } from "../../../components/src";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
const { HelperFunctions: helper } = require("../../../components/src/HelperFunctions")
import { DropDownIcon } from "./assets";
// Customizable Area End

export default class EditProfileDetails extends EditProfileDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    InterestsField = () => {
        const {
            isInterestModalOpen
        } = this.state;
        return (
            <StyledInterestsField className="EditProfileDetails_StyledInterestsField" onClick={this.toggleInterestModal}>
                <p>Select Interest*</p>
                <img src={DropDownIcon} className={isInterestModalOpen ? "rotate" : ""} />
            </StyledInterestsField>
        )
    }
    EditProfileContainer = () => {
        const {
            image_url,
            full_name,
            user_name,
            phone_number,
            country_code,
            date_of_birth,
            email,
            isInterestModalOpen,
            interests,
            selectedInterests,
            currentSelectedInterests,
            isNewInterestAddApiRunning
        } = this.state;
        return (
            <StyledEditProfileContainer className="EditProfileCOntainer_StyledEditProfileContainer">
                <GoBack displayText="Edit Profile" goBack={this.goBack} />
                <form autoComplete="off" onSubmit={(event) => {
                    event.preventDefault();
                    this.onSubmit();
                }}>
                    <div className="form-row">
                        <ImagePicker selectedImage={image_url} onSelectImage={this.onSelectImage} />
                    </div>
                    <div className="form-row" style={{ marginTop: "0.5rem" }}>
                        <div className="form-col">
                            <InputText
                                value={full_name}
                                type="text"
                                onChange={(event) => {
                                    const sanitizedValue = event.target.value.replace(/^\s+/, '').replace(/[^A-Za-z\s]/g, '');
                                    this.onInputChange("full_name", sanitizedValue);
                                }}
                                label="Full Name*"
                                inputStyles={{
                                    textTransform: "capitalize"
                                }}
                                maxLength={42}
                            />
                        </div>
                        <div className="form-col">
                            <InputText
                                value={user_name}
                                type="text"
                                onChange={(event) => {
                                    const sanitizedValue = event.target.value.trim();
                                    this.onInputChange("user_name", sanitizedValue);
                                }}
                                label="User Name*"
                                maxLength={42}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-col" style={{ display: "flex", gap: "0.5rem" }}>
                            <div style={{ width: "5.5rem", display: "flex", flexShrink: 0 }}>
                                <div className="country-code">
                                    {country_code}
                                    <img src={DropDownIcon} />
                                </div>
                            </div>
                            <div style={{ width: "100%" }}>
                                <InputText
                                    value={phone_number}
                                    type="number"
                                    onChange={(event) => this.onInputChange("phone_number", event.target.value)}
                                    label="Phone Number*"
                                    containerClasses="phone-field"
                                    readonly={true}
                                />
                            </div>
                        </div>
                        <div className="form-col">
                            <InputText
                                value={date_of_birth ? helper.formatDate(date_of_birth, "D MMMM YYYY") : ""}
                                label="Select Date Of Birth"
                                type="text"
                                readonly={true}
                                onChange={() => { }}
                                onClick={this.openDatePicker}
                            />
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    variant="inline"
                                    format="DD MMMM YYYY"
                                    margin="normal"
                                    id="text_id"
                                    value={date_of_birth}
                                    onChange={this.onDateChangeHandler}
                                    className="customDatePicker"
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                        id: "mui-date-picker"
                                    }}
                                    style={{ display: "none" }}
                                    PopoverProps={{
                                        className: "add-appointment-date-picker"
                                    }}
                                    autoOk={true}
                                    DialogProps={{
                                        className: "datePickerDialog",
                                    }}
                                    disableFuture
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-col">
                            <InputText
                                value={email}
                                type="email"
                                onChange={(event) => this.onInputChange("email", event.target.value)}
                                label="Email"
                                maxLength={42}
                            />
                        </div>
                        <div className="form-col" style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                            <div>
                                {this.InterestsField()}
                            </div>
                            {
                                selectedInterests.length > 0 && (
                                    <div style={{ display: "flex", gap: "0.5rem", flexWrap: "wrap" }}>
                                        {
                                            selectedInterests.map(interest => {
                                                return (
                                                    <Chip
                                                        displayText={interest.attributes.name}
                                                        id={interest.id}
                                                        onRemove={this.removeInterestFromSelectedInterest}
                                                        key={interest.id}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="form-row" style={{ justifyContent: 'center', alignItems: "center" }}>
                        <Button
                            text="Update"
                            buttonStyles={{
                                background: "linear-gradient(180deg, rgba(243, 127, 120, 0.75) 0%, rgba(247, 83, 103, 0.75) 100%)",
                                width: "21.4375rem"
                            }}
                            disabled={this.disableSubmitButton()}
                            buttonType="submit"
                        />
                    </div>
                </form>
                {
                    isInterestModalOpen && (
                        <SelectInterest
                            interests={interests}
                            currentSelectedInterest={currentSelectedInterests}
                            open={isInterestModalOpen}
                            onClose={this.toggleInterestModal}
                            setCurrentSelectInterest={this.setCurrentSelectedInterest}
                            onAddInterests={this.onAddInterests}
                            onSubmitNewInterest={this.onSubmitNewInterest}
                            isNewInterestAdding={isNewInterestAddApiRunning}
                        />
                    )
                }
            </StyledEditProfileContainer>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        return (
            <NavigationMenu {...this.props} children={this.EditProfileContainer()} />
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const StyledEditProfileContainer = styled("div")({
    "&.EditProfileCOntainer_StyledEditProfileContainer": {
        display: "flex",
        flexDirection: "column",
        gap: "2.5rem",
        padding: "1rem 1.5rem",
        "& > form": {
            display: "flex",
            flexDirection: "column",
            gap: "2.5rem",
            padding: "0rem 5rem",
            "& > .form-row": {
                display: "flex",
                width: "100%",
                gap: "2%",
                "& > .form-col": {
                    width: "49%",
                    "& .country-code": {
                        width: "100%",
                        height: "3rem",
                        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
                        borderRadius: "2.5rem",
                        border: "1px solid rgba(25, 181, 185, 0.27)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "0.5rem",
                        fontSize: "0.875rem",
                        fontWeight: 500,
                        color: "rgba(255, 255, 255, 1)",
                        flexShrink: 0,
                        cursor: "pointer"
                    }
                }
            },
            "& .TextInput_StyledInputText": {
                "&.phone-field": {
                    "& label": {
                        "&.focused": {
                            transform: 'translate(-110px,-38px)'
                        }
                    },
                }
            },
            [`@media screen and (max-width: 900px)`]: {
                padding: 0,
                "& > .form-row": {
                    flexDirection: "column",
                    gap: "2rem",
                    "& > .form-col":{
                        width: "100% !important",
                    }
                },
            },
        },
        "& .rotate": {
            transform: "rotate(180deg)"
        }
    }
})

const StyledInterestsField = styled("div")({
    "&.EditProfileDetails_StyledInterestsField": {
        width: "100%",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        height: "3rem",
        borderRadius: "6.25rem",
        border: "1px solid rgba(25, 181, 185, 0.27)",
        padding: "0 1rem",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxSizing: "border-box",
        cursor: "pointer",
        "& > p": {
            fontSize: "1rem",
            fontWeight: 400,
            color: "rgba(255, 255, 255, 1)"
        },
    }
})
// Customizable Area End
