import React, { useState } from "react";
import { styled } from "@material-ui/styles";
import { Dialog, IconButton } from "@material-ui/core";
import { IInterest } from "./CommonTypes";
import ErrorMessage from "./ErrorMessage.web";
import ModalLoader from "./ModalLoader.web";

interface Props {
    interests: IInterest[];
    open: boolean;
    onClose: () => void;
    onSubmitNewInterest?: (event: string) => void;
    currentSelectedInterest: IInterest[];
    setCurrentSelectInterest: (event: IInterest[])=>void;
    onAddInterests?: (event: IInterest[])=>void;
    isNewInterestAdding?: boolean;
}


const SelectInterest: React.FC<Props> = (props: Props) => {
    const { interests, open, onClose, onSubmitNewInterest, currentSelectedInterest, setCurrentSelectInterest, onAddInterests, isNewInterestAdding } = props;
    // const [currentSelectedInterest, setCurrentSelectInterest] = useState<IInterest[]>([])
    const [isUpdated, setIsUpdated] = useState<boolean>(false)
    const [addInterestModalError, setError] = useState("");
    const [newInterest, setNewInterest] = useState("")
    const [isAddNewInterestInputOpen, setIsAddNewInterestInputOpen] = useState(false);

    const checkInterestAlreadyAdded = (interestId: string) => {
        return currentSelectedInterest.find(item => item.id === interestId || item.attributes.name.toLowerCase() === interestId.toLowerCase())
    }

    const onClickInterest = (interest: IInterest) => () => {
        const clickedOption = interests.find((option) => option.id === interest.id);

        if (clickedOption) {
            const isExist = currentSelectedInterest.find(item => item.id === clickedOption.id);
            if (!isExist && currentSelectedInterest.length < 5) {
                // if item already not exist and selectedItems length < 5 then add it
                const updatedSelectedItems = [...currentSelectedInterest, clickedOption]
                
                setCurrentSelectInterest(updatedSelectedItems);
                setIsUpdated(true);
            }
        }

    };

    const handleRemoveInterest = (interestId?: string) => {
        setCurrentSelectInterest(currentSelectedInterest.filter(
            (item) => item.id !== interestId
        ));
        setIsUpdated(true);
    };

    const submitNewInterest = () => {
        if (currentSelectedInterest.length >= 5) {
            setError("Can't select more than 5 interests")
            return;
        }
        if (checkInterestAlreadyAdded(newInterest)) {
            setError("Interest already selected")
            return;
        }
        const exist = interests.find(option => option.attributes.name.toLowerCase() === newInterest.toLowerCase());
        if (exist) {
            setCurrentSelectInterest([...currentSelectedInterest, exist])
            setNewInterest("");
            setIsAddNewInterestInputOpen(true);
            return;
        }

        onSubmitNewInterest?.(newInterest);
        setNewInterest("");
        setIsAddNewInterestInputOpen(false);
        setError("");
        setIsUpdated(true);
    }

    const onAddInterestsHandler = ()=>{
        onAddInterests?.(currentSelectedInterest);
    }

    const AddNewInterestInput = (value: string, readonly: boolean = false, interestId?: string, onClose?: (interestId?: string) => void, hideCheck?: boolean) => {
        return (
            <StyledAddNewInterestInput className="SelectInterest_StyledAddNewInterestInput">
                <input placeholder="Enter interest" value={value} readOnly={readonly} onChange={(event) => setNewInterest(event.target.value)} />
                <div className="icons">
                    {
                        !hideCheck && (
                            <div className={`icon ${value ? "" : "disabled"}`}
                                onClick={() => {
                                    submitNewInterest();
                                }}
                            >
                                <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.54993 11.6534L0.215332 6.31881L1.28456 5.24961L5.54993 9.51499L14.7153 0.349609L15.7845 1.41881L5.54993 11.6534Z" fill="#F37976" />
                                </svg>
                            </div>
                        )
                    }
                    <div className="icon" onClick={() => {
                        if (onClose) {
                            onClose(interestId);
                        }
                    }}>
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.227 9.83555L0.163574 8.77213L3.93667 4.99902L0.163574 1.25092L1.227 0.1875L5.0001 3.9606L8.7482 0.1875L9.81162 1.25092L6.03852 4.99902L9.81162 8.77213L8.7482 9.83555L5.0001 6.06245L1.227 9.83555Z" fill="#F37976" />
                        </svg>
                    </div>
                </div>
            </StyledAddNewInterestInput>
        )
    }

    const closeAddNewInterestInput = () => {
        setIsAddNewInterestInputOpen(false);
        setNewInterest("");
        setError("")
    };


    return (
        <Dialog
            open={open}
            style={{ display: "flex", justifyContent: "center", alignItems: "center", zIndex: 3 }}
            onClose={onClose}
            PaperProps={{
                style: { background: "transparent" }
            }}
        >
            <StyledInterestPopup className="SelectInterest_StyledInterestPopup">
                <div className="header">
                    <p>Select Interest</p>
                    <div className="icon">
                        <IconButton data-test-id="close-interest-modal" onClick={onClose}>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.227 9.83555L0.163574 8.77213L3.93667 4.99902L0.163574 1.25092L1.227 0.1875L5.0001 3.9606L8.7482 0.1875L9.81162 1.25092L6.03852 4.99902L9.81162 8.77213L8.7482 9.83555L5.0001 6.06245L1.227 9.83555Z" fill="white" />
                            </svg>
                        </IconButton>
                    </div>
                </div>
                <div className="interests-container">
                    <div className="interests">
                        {
                            interests.map(option => {
                                return (
                                    <div data-testId="option" className={`item ${checkInterestAlreadyAdded(option.id) ? "selected" : ""} ${currentSelectedInterest.length >= 5 ? "disabled" : ""}`} key={option.id} onClick={onClickInterest(option)}>
                                        {option.attributes.name}
                                        <div className="icon" onClick={(event) => {
                                            event.stopPropagation();
                                            handleRemoveInterest(option.id)
                                        }}>
                                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.227 9.83555L0.163574 8.77213L3.93667 4.99902L0.163574 1.25092L1.227 0.1875L5.0001 3.9606L8.7482 0.1875L9.81162 1.25092L6.03852 4.99902L9.81162 8.77213L8.7482 9.83555L5.0001 6.06245L1.227 9.83555Z" fill="white" />
                                            </svg>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                    <div className="manually-selected">
                        {
                            currentSelectedInterest.filter(interest => interest.attributes.manual).map(interest => {
                                return (
                                    <div key={interest.id}>
                                        {AddNewInterestInput(interest.attributes.name, true, interest.id, handleRemoveInterest, true)}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {
                    isAddNewInterestInputOpen && (
                        AddNewInterestInput(newInterest, false, "", closeAddNewInterestInput, false)
                    )
                }
                {
                    addInterestModalError && (
                        <ErrorMessage error={{ message: addInterestModalError }} />
                    )
                }
                <button
                    disabled={currentSelectedInterest.length >= 5 || isAddNewInterestInputOpen}
                    className="add-new"
                    onClick={()=> setIsAddNewInterestInputOpen(true)}
                >Add New Interest</button>
                <button onClick={onAddInterestsHandler} className="add-btn" disabled={isAddNewInterestInputOpen || !isUpdated}>Add</button>
                <ModalLoader isLoading={isNewInterestAdding} />
            </StyledInterestPopup>
        </Dialog>
    )
}

const StyledInterestPopup = styled("div")({
    "&.SelectInterest_StyledInterestPopup": {
        width: "20.4375rem",
        maxHeight: "80vh",
        borderRadius: "1.5rem",
        background: "linear-gradient(0deg, #23404B, #23404B)",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        userSelect: "none",
        overflowX: "hidden",
        position: "relative",
        "& .header": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            "& .icon": {
                position: "absolute",
                top: "0rem",
                right: "1rem",
                transform: "translate(15%,-5%)"
            },
            "& > p": {
                fontSize: "1.125rem",
                fontWeight: 500,
                color: "rgba(255, 255, 255, 1)"
            }
        },
        "& .interests-container": {
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            overflowY: "auto",
            flex: 1,
            marginBottom: "0.5rem",
            "& .interests": {
                display: "flex",
                flexWrap: "wrap",
                gap: "0.5rem",
                padding: "0.7rem 0rem",
                "& .item": {
                    height: "1.8125rem",
                    borderRadius: "1rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.5rem",
                    padding: "0rem 0.5rem",
                    border: "1px solid rgba(243, 121, 118, 1)",
                    color: "rgba(243, 121, 118, 1)",
                    fontSize: "0.875rem",
                    fontWeight: 400,
                    cursor: "pointer",
                    "& > .icon": {
                        display: "none"
                    },
                    "&.selected": {
                        background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
                        color: "rgba(255, 255, 255, 1)",
                        fontWeight: 500,
                        "& > .icon": {
                            display: "block"
                        }
                    },
                    "&:not(.selected)": {
                        "&.disabled": {
                            opacity: 0.5,
                            cursor: "not-allowed"
                        }
                    }
                },

            },
            "& .manually-selected": {
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
            }
        },
        "& .add-btn": {
            border: "none",
            outline: "none",
            height: "3rem",
            width: "100%",
            background: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.3)",
            color: "rgba(255, 255, 255, 1)",
            fontSize: "1rem",
            fontWeight: 500,
            borderRadius: "3.5rem",
            cursor: "pointer",
            "&:disabled": {
                opacity: 0.5,
                cursor: "not-allowed"
            }
        },
        "& .add-new": {
            outline: "none",
            border: "none",
            background: "transparent",
            width: "100%",
            fontSize: "1rem",
            fontWeight: 500,
            color: "rgba(243, 121, 118, 1)",
            margin: "1rem 0rem",
            cursor: "pointer",
            "&:disabled": {
                opacity: 0.5,
                cursor: "not-allowed"
            }
        },
    }
})

const StyledAddNewInterestInput = styled("div")({
    "&.SelectInterest_StyledAddNewInterestInput": {
        display: "flex",
        alignItems: "center",
        background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
        border: "1px solid rgba(25, 181, 185, 0.27)",
        borderRadius: "6.25rem",
        padding: "0rem 1rem",
        height: "3rem",
        flexShrink: 0,
        width: "100%",
        boxSizing: "border-box",
        "& input": {
            width: "100%",
            outline: "none",
            border: "none",
            background: "transparent",
            fontSize: "1rem",
            color: "rgba(255, 255, 255, 1)",
        },
        "& .icons": {
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            "& .icon": {
                cursor: "pointer",
                "&.disabled": {
                    cursor: "not-allowed",
                    opacity: 0.5
                }
            }
        }
    }
})

export default SelectInterest;