import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  styled,
  Checkbox
} from "@material-ui/core"
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { EventInput, DayHeaderContentArg } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CustomDateField } from "./components/CustomDateField";
import CustomEvent from "./components/CustomEvent.web";
import CalendarHeader from "./components/CalendarHeader.web";
import ReminderDetailsPopup from "../../tasks/src/components/ReminderDetailsPopup.web";
import ConfirmationPopUp from "../../../components/src/ConfirmationPopUp.web";
// Customizable Area End

import SchedulingController, {
  Props
} from "./SchedulingController";

export default class Scheduling extends SchedulingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  EventRenderer = (eventObj: EventInput) => {
    return (
      <CustomEvent eventObj={eventObj} redirectTo={this.goToAppointmentDetails} />
    )
  }

  renderDayContents = (dayValue: number, dateValue: Date) => {
    const selected = this.IsEqualDate(dateValue, this.state.currentDate) ? "selected" : "";
    const past = this.isPastDate(dateValue) ? "past" : "";
    const today = this.IsEqualDate(dateValue, new Date()) ? "today" : "";
    return (
      <StyledCustomDay className={`${selected} ${this.dayClassName(dateValue)} ${past} ${today}`}>
        {dayValue}
        {this.getNumberOfEventItemsOnADate(dateValue).map((item, index) => <span className={`${item.colorClass}`}></span>)}
      </StyledCustomDay>
    )
  };

  Toolbar = () => {
    const { currentCalendarView, currentDate, isDatePickerOpen, calendarFilters } = this.state;

    return (
      <StyledToolbar className="toolbar">
        <div className="left">
          <button className="today-btn" onClick={this.goToCurrentDate}>Today</button>
          <div className="navigation-btn-container">
            <button onClick={this.goToPrev}>
              <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.0693 10.2206L0.195312 5.34664L5.0693 0.472656L5.97777 1.38113L2.01225 5.34664L5.97777 9.31216L5.0693 10.2206Z" fill="white" />
              </svg>
            </button>
            <div></div>
            <button onClick={this.goToNext}>
              <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.30961 0.466869L6.18359 5.34086L1.30961 10.2148L0.401136 9.30637L4.36665 5.34086L0.401136 1.37534L1.30961 0.466869Z" fill="white" />
              </svg>
            </button>
          </div>
          <div className="date-picker" id="date-picker">
            <DatePicker
              open={isDatePickerOpen}
              selected={currentDate}
              onChange={this.goToSelectedDate}
              customInput={
                <CustomDateField currentDate={currentDate} />
              }
              renderDayContents={this.renderDayContents}
              onCalendarOpen={()=>this.onDatePickerChange(currentDate)}
              onMonthChange={this.onDatePickerChange}
              onSelect={this.onDateSelectFromDatePicker}
              onClickOutside={this.clickedOutside}
              onInputClick={this.toggleDatePicker}
            />
          </div>
        </div>
        <div className="right">
          <Box className="event-types" display={"flex"} gridGap={8}>
            <Box>
              <Checkbox checked={calendarFilters.appointments} onClick={()=> this.handleCalendarFilters("appointments", !calendarFilters.appointments)} className="event-type-color-box appointment-box" />
              <Typography component={"span"}>Appointment</Typography>
            </Box>
            <Box>
              <Checkbox checked={calendarFilters.events} onClick={()=> this.handleCalendarFilters("events", !calendarFilters.events)} className="event-type-color-box event-box" />
              <Typography component={"span"}>Event</Typography>
            </Box>
            <Box>
              <Checkbox checked={calendarFilters.reminders} onClick={()=> this.handleCalendarFilters("reminders", !calendarFilters.reminders)} className="event-type-color-box reminder-box" />
              <Typography component={"span"}>Reminder</Typography>
            </Box>
            <Box>
              <Checkbox checked={calendarFilters.schedule} onClick={()=> this.handleCalendarFilters("schedule", !calendarFilters.schedule)} className="event-type-color-box schedule-box" />
              <Typography component={"span"}>Schedule</Typography>
            </Box>
          </Box>
          <div className="view-container">
            <button onClick={this.setDayView} className={currentCalendarView === "timeGridDay" ? "active" : ""}>Day</button>
            <button onClick={this.setWeekView} className={currentCalendarView === "timeGridWeek" ? "active" : ""}>Week</button>
            <button onClick={this.setMonthView} className={currentCalendarView === "dayGridMonth" ? "active" : ""}>Month</button>
          </div>
        </div>
      </StyledToolbar>
    )
  }
  CalenderHeader = (argValue: DayHeaderContentArg) => {
    return (
      <StyledCalenderHeader className={`${argValue.view.type} ${argValue.isToday ? "today" : ""}`}>
        {
          argValue.view.type === "timeGridWeek" && (
            <p>{argValue.date.getDate()}</p>
          )
        }
        {argValue.view.type === "timeGridDay" && <span className="day date">{argValue.date.toLocaleDateString('en-US', { day: 'numeric' })}</span>}
        <p className="day">{argValue.date.toLocaleDateString('en-US', { weekday: 'long' })}</p>
      </StyledCalenderHeader>
    )
  }

  NowIndicator = ()=>{
    return (
      <StyledNowIndicator>
        <div className="dot left"></div>
        <div className="dot right"></div>
      </StyledNowIndicator>
    )
  }

  HeaderComp = () => {
    return(
      <CalendarHeader redirectTo={this.redirectTo} goBack={this.goBack} />
    )
  }

  CalendarContainer = () => {
    const { calendarRef, currentDate, events, selectedReminderItem, deleteReminderConfirmModalData } = this.state;
    return (
      <StyledCalendarContainer>
         {this.HeaderComp()}
        <div className="fullcalendar">
          {this.Toolbar()}
          <div className="calender-container">
            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay',
              }}
              initialView="timeGridDay"
              initialDate={currentDate}
              editable={false}
              selectable={true}
              selectMirror={false}
              dayMaxEvents={3}
              moreLinkText={(num) => `+ ${num}`}
              weekends={true}
              events={events as EventInput}
              eventContent={this.EventRenderer}
              dayHeaderContent={this.CalenderHeader}
              allDaySlot={false}
              nowIndicator={true}
              eventMaxStack={3}
              // slotDuration="00:15:00" // Adjust the slot duration if needed
              slotEventOverlap={true} // Prevent slot overlap
              eventOverlap={true} // Allow events to overlap
              nowIndicatorContent={this.NowIndicator}
              dayHeaders={true}
              slotDuration="00:15:00" // Set each time slot to 1 hour
              slotLabelInterval="01:00:00" // Show time labels every 1 hour
              viewDidMount={this.viewDidMount} // Attach the viewDidMount callback
              eventClick={(argValue)=>this.goToAppointmentDetails(argValue.event.extendedProps.eventType, argValue.event._def.publicId)}
              slotLabelFormat={{
                hour: 'numeric',
                hour12: true
              }}
            />
          </div>
        </div>
        <ReminderDetailsPopup
          reminder={selectedReminderItem}
          onCloseUserListModal={this.onCloseReminderDetailModal}
          onDelete={this.openReminderDeleteModal}
          onEdit={(reminderId: string) => this.redirectTo("EditReminder", { id: reminderId })}
          onMarkAsComplete={(reminderId: string) => this.markRemindersAsComplete(reminderId)}
        />
        <ConfirmationPopUp
          open={Boolean(deleteReminderConfirmModalData)}
          header={deleteReminderConfirmModalData?.header as string}
          message={deleteReminderConfirmModalData?.message as string}
          onAccept={this.onAcceptDeleteConfirmation}
          onReject={this.onCloseDeleteReminderConfirmationModal}
          onClose={this.onCloseDeleteReminderConfirmationModal}
        />
      </StyledCalendarContainer>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <NavigationMenu navigation={this.props.navigation} id={this.props.id} children={this.CalendarContainer()} />
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledCalendarContainer = styled("div")({
  padding: "1rem 1rem 0rem 1rem",
  "& .fc .fc-toolbar.fc-header-toolbar": {
    display: "none"
  },
  "& .fc-theme-standard td, .fc-theme-standard th": {
    border: "1px solid #FFFFFF0F",
    borderBottom: "none",
  },
  "& .fc-theme-standard th": {
    borderTop: "none",
    "&:first-child": {
      borderTop: "1px solid #FFFFFF0F",
    }
  },
  "& .fc-theme-standard .fc-popover" : {
    background: "linear-gradient(0deg, #23404B, #23404B)"
  },
  "& .fc-popover-close": {
    opacity: 1,
    color: "#ffffff"
  },
  "& .fc-popover-title": {
    color: "#ffffff"
  },
  "& .fc .fc-scrollgrid-liquid": {
    background: "linear-gradient(0deg, #23404B, #23404B)",
    borderRadius: "0rem 0rem 1rem 1rem",
    overflow: "hidden",
  },
  "& .fc-theme-standard .fc-scrollgrid": {
    border: "none"
  },
  "& .fc .fc-col-header-cell-cushion": {
    color: "#ffffff",
    fontSize: "0.875rem",
    fontWeight: 400,
    display: "flex",
    padding: 0
  },
  "& .fc .fc-daygrid-day-number, .fc .fc-timegrid-axis-cushion, .fc .fc-timegrid-slot-label-cushion": {
    color: "#ffffff",
    fontSize: "0.875rem",
    fontWeight: 400
  },
  "& .fc-h-event, .fc-v-event": {
    backgroundColor: "transparent",
    border: "none"
  },
  "& .fc-daygrid-event": {
    background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
    border: "1px solid #19B5B945",
    borderRadius: "5px",
    padding: "0px",
    cursor: "pointer",
    overflow: "auto",
  },
  "& .fc .fc-daygrid-day-top": {
    justifyContent: "center"
  },
  "& .fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link": {
    boxShadow: "none"
  },
  "& .fc-event-main": {
    background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
    border: "1px solid #19B5B945",
    borderRadius: "5px",
    padding: "0px",
    cursor: "pointer",
    overflow: "auto",
  },
  "& .fc-timegrid-more-link": {
    background: "inherit",
    height: "30px"
  },
  "& .fc-day-today": {
    borderTop: "1px solid #F5686F !important"
  },
  "& .fc .fc-scroller":{
    overflow:"auto !important"
  },
  "& .fc .fc-scroller-liquid-absolute":{
    position:"relative"
  },
  "& .fc-timegrid-now-indicator-container":{
    position:"initial"
  },
  "& .fc .fc-timegrid-now-indicator-line":{
    border:"none"
  },
  "& .fc-timegrid-now-indicator-arrow":{
    display:"none"
  },
  "& .fc-timegrid-slot.custom-slot": {
    padding: "0.5em 0",
    border:"none !important"
  },
  "& .fc-timegrid-slot.middle-slot": {
    borderBottom:"2px dashed #FFFFFF0F !important"
  },
  "& .fc-timegrid-slot.end-slot": {
    borderBottom:"1px solid #FFFFFF0F !important"
  },
  "& .timeGridDay": {
    "& p": {
      textAlign: "start",
      padding: "10px 0px"
    }
  },
  "& .fullcalendar": {
    background: "linear-gradient(0deg, #23404B, #23404B)",
    borderRadius: "1rem",
    marginTop: "1.5rem"
  },
  "& .react-datepicker__triangle": {
    display: "none"
  },
  "& .react-datepicker": {
    background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
    border: "1.21px solid #19B5B945",
    borderRadius: "1rem",
    overflow: "hidden"
  },
  "& .react-datepicker__header": {
    // background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
    background: "transparent",
    border: "none",
    paddingBottom: 0,
    paddingTop: "1rem"
  },
  "& .react-datepicker__navigation--next": {
    right: "15%",
    top: "2.8%"
  },
  "& .react-datepicker__navigation--previous": {
    left: "15%",
    top: "2.8%"
  },
  "& .react-datepicker__current-month": {
    color: "#ffffff",
    fontSize: "1rem",
    fontWeight: "500",
    marginBottom: "0.5rem"
  },
  "& .react-datepicker__day-name": {
    color: "#ffffff",
    fontSize: "0.875rem",
    fontWeight: "500"
  },
  "& .react-datepicker-popper": {
    zIndex: 2,
    marginLeft: "-6.25rem"
  },
  '& .react-datepicker-popper[data-placement^="bottom"]': {
    padding: "0.5rem"
  },
  "& .react-datepicker__day": {
    "&:hover": {
      background: "transparent",
      borderRadius: "100%"
    }
  },
  "& .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected": {
    background: "transparent",
    // borderRadius: "100%"
  },
  "& .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name": {
    margin: "0.5rem"
  },
})

const StyledToolbar = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "1rem",
  flexWrap: "wrap",
  "& button": {
    border: "none",
    outline: "none",
    background: "transparent",
    cursor: "pointer"
  },
  "& .left": {
    display: "flex",
    alignItems: "center",
    gap: "1.25rem",
    "& .today-btn": {
      width: "5.1875rem",
      height: "2.25rem",
      border: "1px solid #F75367",
      borderRadius: "62.5rem",
      color: "#F75367",
      fontSize: "1rem",
      fontWeight: 500
    },
    "& .navigation-btn-container": {
      display: "flex",
      width: "4.0625rem",
      height: "2.25rem",
      borderRadius: "0.3125rem",
      background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
      alignItems: "center",
      padding: "0rem 0.5rem",
      justifyContent: "space-between",
      "& > div": {
        height: "60%",
        width: "1px",
        background: "#FFFFFF2E",
      }
    },
    "& .selected-date": {
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
      color: "#FFFFFF",
      fontSize: "1.25rem",
      fontWeight: 500,
      cursor: "pointer",
      [`@media screen and (max-width: 950px)`]: {
        fontSize: "1rem"
      },
    }
  },
  "& .right": {
    display: "flex",
    alignItems: "center",
    gap: "1.25rem",
    "& .view-container": {
      width: "8.8125rem",
      height: "2.25rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      background: "linear-gradient(180deg, #23404B 0%, #19282F 100%)",
      border: "1px solid #19B5B945",
      borderRadius: "3.125rem",
      padding: "0rem 0.25rem",
      "& button": {
        width: "4rem",
        height: "1.75rem",
        color: "#FFFFFF",
        opacity: "50%",
        fontSize: "0.875rem",
        padding: "0rem 0.25rem",
        borderRadius: "3.125rem",
        transition: "all 0.3s ease-in-out",
        "&.active": {
          background: "linear-gradient(90deg, #FF7F77 0%, #FF6376 100%)",
          fontWeight: 500,
          opacity: 1
        }
      },
    },
    "& .event-types": {
      fontSize: "12px",
      color: "#ffffff",
      fontWeight: 500,
      "& div": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      },
      "& span": {
        fontSize: "12px"
      },
      "& .event-type-color-box": {
        "& svg":{
          width: "1rem",
          height: "1rem"
        },
        padding: "4px"
      },
      "& .appointment-box": {
        "& svg":{
          fill: "rgba(200, 245, 104, 1)"
        }
      },
      "& .event-box": {
        "& svg":{
          fill: "rgba(234, 104, 245, 1)"
        }
      },
      "& .reminder-box": {
        "& svg":{
          fill: "rgba(255, 126, 85, 1)"
        }
      },
      "& .schedule-box": {
        "& svg":{
          fill: "rgba(227, 111, 181, 1)"
        }
      },
    },
    "& .filter-btn": {
      height: "2.25rem",
      width: "5.9375rem",
      border: "1px solid #F75367",
      color: "#F75367",
      fontSize: "1rem",
      fontWeight: "500",
      borderRadius: "62.5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "0.3rem"
    }
  },
  [`@media screen and (max-width: 950px)`]: {
    flexDirection: "column",
    gap: "1.5rem"
  },
})

const StyledCalenderHeader = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  width: "100%",
  padding: "0.2rem 0.6rem",
  "& p": {
    margin: 0,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    padding: "0px !important"
  },
  "&.timeGridWeek": {
    justifyContent: "flex-start",
    "& p": {
      textAlign: "left"
    },
  },
  "& .date": {
    textAlign: "left"
  },
  "&.today": {
    borderTop: "1px solid #F5686F",
    color: "#F5686F",
    fontWeight: 500
  }
})

const StyledCustomDay = styled("div")({
  position: "relative",
  color: "#FFFFFFCC",
  fontWeight: 400,
  fontSize: "0.875rem",
  borderRadius: "100%",
  width:"2rem",
  height:"2rem",
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  transition:"background-color 0.3s ease-in-out",
  "& .appointment-box": {
    backgroundColor: "rgba(200, 245, 104, 1)"
  },
  "& .event-box": {
    backgroundColor: "rgba(234, 104, 245, 1)"
  },
  "& .reminder-box": {
    backgroundColor: "rgba(255, 126, 85, 1)"
  },
  "& .schedule-box": {
    backgroundColor: "rgba(227, 111, 181, 1)"
  },
  "&.event-date span": {
    position: "absolute",
    width: "0.25rem",
    height: "0.25rem",
    borderRadius: "100%",
  },
  "&.event-date": {
    "& span:nth-child(1)": {
      right: 3,
      top: 5,
    },
    "& span:nth-child(2)": {
      right: 10,
      top: 5,
    },
    "& span:nth-child(3)": {
      right: "3px",
      top: "11px",
    },
    "& span:nth-child(4)": {
      right: "3px",
      top: "16px",
    }
  },
  "&.today":{
    border:"1px solid #F5686F",
  },
  "&.event-date.past span": {
    backgroundColor: "#808080 !important"
  },
  "&.selected.event-date span, &.today.event-date span": {
    display: "none"
  },
  "&.selected": {
    backgroundImage: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
    backgroundColor: "#F5686F",
  },
  "&:hover": {
    fontWeight: 500,
    backgroundImage: "linear-gradient(180deg, #F37F78 0%, #F75367 100%)",
    backgroundColor: "#F5686F",
    borderRadius: "100%",
    color: "#ffffff",
    "& span": {
      display: "none"
    }
  }
})

const StyledNowIndicator = styled("div")({
  backgroundColor:"#F75367",
  height:"1px",
  position:"relative",
  zIndex:5,
  "& .dot":{
    width:"0.5rem",
    height:"0.5rem",
    backgroundColor:"#F75367",
    borderRadius:"100%",
    position:"absolute",
    // transform:"translateY(50%)",
    top:"-0.21rem",
    "&.left":{
      left:0
    },
    "&.right":{
      right:0
    }
  },
})

// Customizable Area End
